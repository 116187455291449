








































import Component from 'vue-class-component'
import BaseView from '@/views/BaseView'

@Component
export default class NotAuthorized extends BaseView {
  get isDark(): boolean {
    return this.$store.state.appConfig.layout.skin === 'dark'
  }
}
